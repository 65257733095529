.container {

}

.formControl {
	width: 100%;
}

.box > div {
	margin-bottom: 10px;
}

.box > ul {
	box-shadow: 0px 0px 7px 4px lightgray;
}