.container {
	flex: 1;
	padding: 0 24px;
	font-family: inherit;
}

.subContainer {
	background-color: white;
    padding: 1px 10px 15px 15px;
	margin-bottom: 10px;
}  
