.container {
	flex: 1;
	padding: 12px;
	font-family: inherit;
}


.container div {
	justify-content: center;
;}
